.back-button {
    position: fixed;
    top: 16px;
    left: 16px;
    font-size: 12px;
    color: #bbb;
    padding: 5px;
    cursor: pointer;
    z-index: 9999;
}

.back-button svg {
    float: left;
}

.back-button span {
    float: left;
    margin-left: 7px;
    margin-top: 1px;
}