.upload-manager__items {
    text-align: center;
}

.upload-manager__empty-state-text {
    margin-top: 20px;
    margin-bottom: 30px;
}

.upload-manager__empty-state-link {
    font-weight: 700;
    color: var(--color__surface__action);
}

.upload-manager__empty-state-link:hover {
    color: var(--color__surface__action-dark);
}