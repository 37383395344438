.delimiter-wrapper {
    display:table;
    width:100%;
    max-width:100%;
    margin-top: 20px;
}

.delimiter-wrapper .delimiter {
    display:table-row;
    line-height:1.5em;
    font-size: 12px;
    white-space:nowrap;
}
.delimiter-wrapper .delimiter-text {
    font-size:inherit; /* Change font-size in header */
    overflow:hidden;
    display:table-cell;
    vertical-align:middle;
    width:1px;
    table-layout:fixed;
    color: var(--color__surface__blank-darker);
}
.delimiter-wrapper .delimiter-spacer {
    display:table-cell;
}
.delimiter-wrapper .delimiter-text {
    padding:0 10px;
}
.delimiter-wrapper .delimiter-spacer:after {
    display:inline-block;
    width:100%;
    content:".";
    font-size:0;
    color:transparent;
    height:1px;
    background: var(--color__surface__blank-dark);
    vertical-align:middle;
    position:relative;
    top:-1px;
}

.delimiter-url {
    /* color: var(--color__surface__action-dark); */
}

.delimiter-url:hover {
    font-weight: 700;
    color: var(--color__surface__action);
}