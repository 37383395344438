.upload-area {
  position: relative;

  width: 100%;
  margin: 0 auto;
  height: 230px;
  box-sizing: border-box;

  color: var(--color__text-main__blank);
}

.upload-area__icon {
  color: var(--color__surface__action-dark);
}

.upload__settings-advanced {
  padding-bottom: 7px;

  width: 100%;
  overflow: auto;
  position: relative;
}

.upload-area__drag-n-drop {
  border: 2px dashed var(--pattern__color);
}

.upload__settings-advanced__toggle-open,
.upload__settings-advanced__toggle-close {
  margin-left: 11px;
  margin-top: 7px;
  float: left;
  /* position: absolute; */
}

.upload__settings-advanced__toggle-open:hover,
.upload__settings-advanced__toggle-close:hover {
  cursor: pointer;
  color: var(--pattern__color);
}

.upload__settings-advanced__toggle-open {
  color: var(--color__surface__action);
}

.upload__settings-advanced__toggle-text {
  float: right;
  margin-top: 9px;
  margin-left: 7px;
  font-size: 12px;
}

.upload-area__uploaded,
.upload-area__before-upload:hover {
  cursor: pointer;
  animation: fadeIn 0.35s;

  background-image: var(--pattern__background);
  box-shadow: var(--pattern__box-shadow);
  background-color: var(--pattern__color);

  border: 0;
  padding: 3px;
  color: var(--color__text-main__action);
}

.upload-area__before-upload:hover .upload-area__icon {
  color: var(--color__text-main__action);
}

.upload-area__text {
  position: relative;
  width: 94%;
  transform: translate(-50%, -60%);
  top: 50%;
  left: 50%;
  padding: 10px;
  overflow: auto;

  text-align: center;
}

.upload-area__text__filename {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 18px;
}

.upload-area__text__filesize {
  font-weight: 700;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.upload-link__qr {
  width: 146px;
  height: 146px;
}

.upload-link__qr-snow-crash {
  width: 76px;
  height: 76px;
}

.upload-link__qr,
.upload-link__qr-snow-crash {
  border: 8px solid var(--color__text-main__action);
  box-sizing: border-box;
  float: left;
}

.upload-link,
.upload-link a {
  float: right;
  margin-left: -20px;
  font-weight: 600;
  font-size: 16px;
}

.upload-link__progress {
  margin-top: 7px;
  width: 100%;
}

.upload-link__instructions {
  float: right;
  width: 220px;
  margin-top: 115px;
  font-size: 10px;
  text-align: right;
}

.upload-link__instructions__copy-to-clipboard {
  margin-top: -115px;
  margin-bottom: 25px;
  box-sizing: border-box;
  padding: 17px 5px;
  border-width: 3px;
  border-color: var(--color__text-main__action);
  width: 200px;
  float: right;
  text-align: center;
  border-style: dotted;
  background: rgba(255,255,255,0.3);
  height: 50px;
}

.upload-link__instructions__copy-to-clipboard:hover {
  opacity: 0.8;
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

.upload-link__instructions__copy-to-clipboard__confirmation {
  position: fixed;
  top: 60px;
  right: 0;
  width: 200px;
  text-align: center;
  padding: 10px;
  animation: fadeOut ease 2s;
}

@media (max-width: 600px) {
  .upload-link__instructions {
    width: 150px;
  }

  .upload-link,
  .upload-link a {
    font-size: 11px;
  }

  .upload-link__instructions__copy-to-clipboard,
  .upload-link__instructions__copy-to-clipboard__confirmation {
    font-size: 8px;
    width: 150px;
  }
}

/* upload */

.upload__confirmation {
  position: relative;
  /* overflow: auto; */
}

.upload__confirmation__checkbox {
  float: left;
  width: 100%;

  color: var(--color__shadow-main);

  font-size: 7px;
  margin-top: 1px;
  text-align: right;
  padding-top: 5px;
  line-height: 10px;

  display: block;
  position: relative;
  margin-bottom: 3px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.upload__confirmation__checkbox a {
  color: #555;
  font-weight: 500;
}

.upload__confirmation__checkbox a:hover {
  color: var(--color__surface__action);
}

.btn-upload {
  float: right;
  padding: 8px 32px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 3px;
}

/* reset upload button */

.upload-new {
  width: 100%;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 3px;
  margin-bottom: 9px;
}

.upload-new__link {
  color: var(--pattern__color);
  border-bottom: 2px solid var(--pattern__color);
  padding-bottom: 1px;
}

.upload-new__link:hover {
  color: var(--color__surface__action);
  border-bottom: 3px solid var(--pattern__color);
  font-weight: 700;
  cursor: pointer;
}

/* SVG */

svg {
  display: block;
  font: 5em 'Roboto';
  width: 340px;
  height: 140px;
  margin: 0 auto;
}