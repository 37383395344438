.wacky-logo__simple {
    width: 96px;
    height: 96px;
    margin: 0 auto;
}
  
.wacky-subscription__title {
    box-sizing: border-box;
    color: var(--color__text-main__blank);
  
    font-size: 18px;
    font-weight: 700;
  
    margin-top: 18px;
    margin-bottom: 24px;
}
  
.subscription-area {
    position: relative;
  
    margin: 0 auto;
    box-sizing: border-box;
  
    border: 0;
}
  
.subscription-area__text {
    /* position: relative; */
    width: 260px;
    overflow: auto;
    margin: 0 auto;
    overflow: hidden;
  
    text-align: center;
}
  
.subscription-area__text-subsecondary {
    margin-top: 20px;
  
    font-size: 12px;
    color: #bbb;
  
    line-height: 1.3em;
    letter-spacing: 0.1px;
}
  
.action__subscription__main {
    display: flex;
    -webkit-box-pack: var(--button-align,center);
    justify-content: var(--button-align,center);
    -webkit-box-align: center;
  
    -webkit-font-smoothing: antialiased;
  
    line-height: 20px;
    letter-spacing: -0.125px;
  
    border-radius: 5px;
    padding: 8px 0;
    /* font-size: 9px; */
    font-weight: 700;
    width: 100%;
    min-height: 40px;
  
    transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
}
  
.action__subscription__wrapper {
    margin-top: 20px;
}