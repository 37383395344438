/* advanced config */

.advanced-config__wrapper {
    overflow: hidden;
    margin-bottom: 10px;
}

.advanced-config__links-wrapper {
    margin-top: 30px;
}

.advanced-config__links {
    font-size: 14px;
    text-align: center;
    margin-bottom: 15px;
    padding: 0 20px;
}

.advanced-config__link {
    color: var(--color__surface__action);
}

.advanced-config {
    border-top: 1px solid var(--color__shadow-main);
    border-bottom: 1px solid var(--color__shadow-main);
    padding: 10px 0;
}

.advanced-config__link:hover {
    font-weight: 700;
    color: var(--color__surface__action-dark);
}

.advanced-config__item {
    width: 100%;
    overflow: hidden;
}

.advanced-config__item__heading {
    text-transform: uppercase;
    font-size: 10px;
    width: 100%;
    float: left;
    padding: 6px 0px 8px 16px;
    margin-top: 7px;
    /* text-decoration: underline; */
}

.advanced-config__item__options {
    width: 100%;
    position: relative;
}

.advanced-config__item__option {
    padding: 10px 0px;
    float: left;
}

.advanced-config__item__option-current {
    background-color: var(--color__surface__action);
}

.advanced-config__item__option:hover {
    cursor: pointer;
    float: left;
    /* background-color: var(--color__surface__action-dark); */
    background-color: var(--pattern__color);
}

.advanced-config__item__option-current,
.advanced-config__item__option:hover {
    color: var(--color__text-main__action);
    font-weight: 700;
}

.advanced-config__item__heading-secondary {
    color: var(--color__shadow-main);
    width: 38%;
    float: right;
    text-align: right;
}

/* .delimiter {
    width: 100%;
    border-bottom: 1px solid var(--color__shadow-main);
    margin-top: 5px;
    margin-bottom: 5px;
} */