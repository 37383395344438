.report-abuse-page__form {
  padding: 10px 30px;
  font-size: 14px;

  text-align: right;
}

.report-abuse-page__label {
    text-align: left;
    width: 100%;
    font-family: monospace;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 3px;
}

.report-abuse-page__text input,
.report-abuse-page__text textarea {
    background: transparent;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    resize: none;
    margin: 0;
    margin-bottom: 10px;
}

.report-abuse-page__text input {
    height: 26px;
}

.report-abuse-page__text textarea {
    height: 200px;
}