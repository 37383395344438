.wacky-logo-small {
    background: url('../../../../public/icons/android-chrome-512x512.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 9999;
    position: fixed;
}

.wacky-logo-small::before {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 30px;
    height: 100%;
    top: 0;
    filter: blur(30px);
    transform: translateX(-100px) skewX(-15deg);
}

.wacky-logo-small::after {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    width: 30px;
    height: 100%;
    top: 0;
    filter: blur(5px);
    transform: translateX(-100px) skewX(-15deg);
}

.wacky-logo-small:hover {
    background-image: (linear-gradient(to left, #2d8fe5, #d155b8));
    transform: scale(1.1);
    transition: 1.3s;
    cursor: pointer;
}

.wacky-logo-small:hover::before,
.wacky-logo-small:hover::after {
    transform: translateX(300px) skewX(-15deg);
    transition: 1.5s;
}

.wacky-status {
    text-align: center;
    width: 32px;
    height: 12px;
    position: fixed;
    margin-top: 36px;

    color: var(--color__surface__action);
    font-size: 10px;
    letter-spacing: 0.5px;
    font-weight: 500;
}