.transfer-summary {
  position: absolute;
  width: 100%;
  float: left;
  bottom: 10px;
  left: 10px;
}

.transfer-summary__item-password {
  float: left;
  height: 32px;
  width: 33px;
  box-sizing: border-box;
  padding: 3px 0;
}

.transfer-summary__item-left {
  float: left;
  height: 32px;
}

.transfer-summary__item-line {
  height: 16px;
}

.transfer-summary__item-line.line-1 {
  top: 0px;
  position: absolute;
}

.transfer-summary__item-line.line-2 {
  top: 16px;
  position: absolute;
}

.transfer-summary__item-left,
.transfer-summary__item-left .transfer-summary__item-line {
  width: 250px;
}

.transfer-summary__item-left .transfer-summary__item-line,
.transfer-summary__item-left .transfer-summary__item-line .transfer-summary__item__text {
  float: left;
}

.transfer-summary__item__text {
  font-size: 9px;
  margin-top: 4px;
  margin-left: 4px;
  margin-right: 5px;
}