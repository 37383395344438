.contact-page__form {
  padding: 10px 30px;
  font-size: 14px;

  text-align: right;
}

.contact-page__label {
  text-align: left;
  width: 100%;
  font-family: monospace;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 3px;
}

.contact-page__text input,
.contact-page__text textarea {
  background: transparent;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  margin: 0;
  margin-bottom: 10px;
}

.contact-page__text input {
  height: 26px;
}

.contact-page__text textarea {
  height: 149px;
}