.pricing__card {
    float: left;
    /* width: 122px; */
    width: 249px;
    height: 295px;
    margin-bottom: 12px;
    margin-top: 12px;
    margin-left: 12px;
    box-sizing: border-box;
    padding: 7px 10px;
}

.pricing__card__subscription-title {
    width: 100%;
    font-weight: 900;
    /* font-size: 20px; */
    font-size: 32px;
}

.pricing__card__subscription-badge {
    font-size: 10px;
    background: red;
    font-size: 8px;
    color: white;
    position: fixed;
    margin-top: -9px;
    padding: 2px 5px;
    margin-left: 5px;
    border-radius: 4px;
}

.pricing__card__subscription-price {
    width: 100%;
    font-weight: 500;
    /* font-size: 14px; */
    margin-top: 3px;
    font-size: 18px;
}

.pricing__card__subscription-price-strike {
    text-decoration: line-through;
}

.pricing__card__subscription-subprice {
    width: 100%;
    font-weight: 500;
    /* font-size: 14px; */
    margin-top: 3px;
    font-size: 12px;
    height: 18px;
}

.pricing__card__subscription-description {
    margin-top: 7px;
    width: 100%;
    /* height: 70px; */
    height: 40px;
    font-size: 12px;
    border-bottom: 1px solid white;
}

.pricing__card__subscription-features {
    width: 100%;
    /* height: 123px; */
    height: 135px;
    font-size: 12px;
    box-sizing: border-box;
    padding-top: 5px;
    padding-left: 10px;
}

.pricin__card__subscription-features-badge,
.pricin__card__subscription-features-todo {
    font-size: 6px;
    color: white;
    position: absolute;
    margin-top: 0px;
    padding: 2px 5px;
    border-radius: 4px;
}

.pricin__card__subscription-features-badge {
    display: none;
    background: red;
    margin-left: -53px;
}

.pricin__card__subscription-features-todo {
    display: none;
    background: blue;
    margin-left: -28px;
}

.pricing__card__button-subscribe {
    position: relative;
    width: 100%;

    border: 0;
    width: 249px;
    height: 32px;
    margin-left: -10px;
    margin-right: -10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.pricing__card__button-active {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;

    position: relative;
    width: 100%;

    background-color: var(--color__status-success);
    color: white;
    border: 0;
    padding-top: 8px;
    font-size: 14px;
    width: 249px;
    height: 23px;
    margin-left: -10px;
    margin-right: -10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.pricing__card-in-development {
    opacity: 0.2;
}