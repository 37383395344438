.download-area {
  position: relative;

  width: 100%;
  margin: 0 auto;
  height: 230px;
  box-sizing: border-box;
  /* border-radius: 15px; */

  background-image: var(--pattern__background);
  box-shadow: var(--pattern__box-shadow);
  background-color: var(--pattern__color);

  border: 0;
  padding: 3px;
  color: var(--color__text-main__action);
}

.download-area__text {
  position: relative;
  width: 90%;
  transform: translate(-50%, -60%);
  top: 50%;
  left: 50%;
  padding: 20px;
  overflow: auto;

  text-align: center;
}

/* download */

.download__confirmation {
  position: relative;
  overflow: auto;
  height: 46px;
  padding-top: 13px;
}

.download__confirmation__checkbox {
  float: left;
  width: 220px;
  /* max-width: 46%; */

  font-size: 7px;
  margin-top: 6px;
  padding-top: 5px;
  line-height: 10px;

  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-download {
  float: right;
  padding: 8px 22px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
}

/* extra stats */

.download-area__stats {
  position: absolute;
  right: 17px;
  top: 11px;
  font-size: 10px;
}

.download-area__password {
  margin-top: 12px;
}