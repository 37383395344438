.faq__paragraph {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    color: var(--color__surface__blank-very-dark);
    font-size: 14px;
    line-height: 1.2em;

    margin-top: 15px;
    margin-bottom: 12px;
}

.faq__paragraph-title {
    color: var(--color__surface__action);
}

.faq__paragraph-technical {
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 11px;
}