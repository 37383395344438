.header {
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 15px; /* IMPORTANT: same as FeedBear padding for seemless integration */
    padding-left: 16px; /* IMPORTANT: same as FeedBear padding for seemless integration */
    padding-right: 15px;
    z-index: 2000;
    box-sizing: border-box;
    width: 100%;
    background: rgba(246, 249, 251,0.9);
    height: 55px;
}

.header__create-an-account {
    position: fixed;
    top: 23px;
    right: 69px;
}

.header__create-an-account svg {
    position: absolute;
    padding-left: 5px;
    top: 2px;
}

@media (max-width: 600px) {
    .header__create-an-account {
        display: none;
    }
}

.header__item-right {
    float: right;
    margin-left: 10px;
}

.header__item__logo {
    float: left;
}

.header__item__menu-wrapper {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
}

.header__item__menu {
    position: fixed;
    top: 50px;
    right: 0;
    width: 200px;
}

.header__item__menu-toggle {
    position: fixed;
    color: var(--color__surface__action-dark);
    top: 16px;
    right: 16px;
}

.header__item__menu {
    overflow: hidden;
}

.header__item__menu-closed {
    padding: 0;
    margin: 0;
    height: 0px;
    animation: fadeIn ease 1s;
    transition: all;
}

.header__item__menu-open {
    height: auto;
}

.header__item__menu-item {
    height: 30px;
    border-top: 1px solid #AAA;
    padding: 6px 10px;
    box-sizing: border-box;
}

.header__item__menu-icon {
    margin-top: 3px;
    margin-bottom: -3px;
}

.header__language-current {
    color: var(--color__surface__action-dark);
    font-size: 12px;
    font-weight: 700;
    padding-right: 10px;
    border-right: 2px solid var(--color__text-main__blank);
}

.header__item__languages {
    font-size: 10px;
    margin-left: 5px;
}

.header__item__languages-logged-in {
    margin-right: 39px;
}
  
.header__item__languages__icon {
    margin-top: -3px;
}

.header__language-selection {
    padding: 3px 5px;
}

.header__language-selection:hover,
.header__item__menu-item:hover,
.header__item__menu-toggle:hover {
    cursor: pointer;
    color: var(--color__surface__action);
}

.header__item__subscription-status,
.header__item__login-status {
    position: absolute;
    left: 0px;
    margin-left: 60px;
    min-width: 250px;
    text-align: left;
}

.header__item__top-menu {
    font-size: 16px;
    box-sizing: border-box;
    position: relative;
    height: 42px;
    float: left;
    padding-top: 10px;
    padding-right: 5px;
    padding-left: 5px;
    margin-left: 10px;
    margin-right: 5px;
}

.header__item__top-menu:hover {
    color: var(--color__surface__action-dark);
}

.header__item__top-menu__selected {
    border-bottom: 2px solid var(--color__surface__action-dark);
}

a .header__item__top-menu__selected {
    font-weight: 700;
}

.header__item__subscription-status {
    top: 13px;
}

.header__item__login-status {
    top: 27px;
}

.header__login-status__text {
    font-size: 9px;
}

.header__login-status__username {
    font-weight: 700;
    color: var(--color__surface__action-dark);
}