:root {
    scroll-padding: 100px;
    scroll-behavior: smooth;

    --color__status-success: #007720;
    --color__status-error: #ed1010;
    --font-size__status: 13px;

    /* --color__bg-main: #ecf0f3; */
    --color__bg-main: #f6f9fb;
    --color__bg-secondary: #eeeeee;

    --color__text-main__blank: rgba(0, 0, 0, 0.9);
    --color__text-main__action: rgba(255, 255, 255, 1.0);
    --color__text-secondary__blank: rgba(0, 0, 0, 0.7);
    --color__text-secondary__action: rgba(255, 255, 255, 0.7);

    --color__shadow-main: #8ea6b8; /* shadow 45% multiply */
    --color__shadow-inward: #000; /* shadow 45% multiply */
    --color__highlight-main: #fff; /* highlight 100% screen */
    --color__highlight-border: rgba(255, 255, 255, 0.1); /* that 1px border outline in case we need it */
    
    --color__surface__blank: #e8eaec;
    --color__surface__blank-light: #fdffff; /* hack: find CSS color modifiers for that */
    --color__surface__blank-dark: #e3e5e6; /* hack: find CSS color modifiers for that */
    --color__surface__blank-darker: #bfbfbf; /* hack: find CSS color modifiers for that */
    --color__surface__blank-middle-dark: #777777; /* hack: find CSS color modifiers for that */
    --color__surface__blank-very-dark: #333333; /* hack: find CSS color modifiers for that */

    /* MAIN PALETTE v2 (gradient colors from light to dark) */
    --color__surface__action-light: #f72121; /* hack: find CSS color modifiers for that */
    --color__surface__action: #ed1010;
    --pattern__color: #b61313; /* hack: find CSS color modifiers for that */
    --color__surface__action-dark: #a50202; /* hack: find CSS color modifiers for that */
    --color__surface__action-darker: #6c0000; /* hack: find CSS color modifiers for that */

    /* MAIN PALETTE v3 (gradient colors from light to dark) */
    --color__surface__action-light: #f721db; /* hack: find CSS color modifiers for that */
    --color__surface__action: #f000d0;
    --pattern__color: #9c008b; /* hack: find CSS color modifiers for that */
    --color__surface__action-dark: #5e02a5; /* hack: find CSS color modifiers for that */
    --color__surface__action-darker: #3a006c; /* hack: find CSS color modifiers for that */


    --pattern__background: repeating-linear-gradient(
        122deg,
        transparent,
        transparent 2px,
        rgba(0, 0, 0, 0.2) 2px,
        rgba(0, 0, 0, 0.2) 3px,
        transparent 3px,
        transparent 5px,
        rgba(0, 0, 0, 0.2) 5px
    );
    --pattern__box-shadow: inset 0 0 20px var(--color__surface__action-darker);

    --border__highlight: 1px solid var(--color__highlight-border);
    --border__panel__radius: 6px;
    --border__panel__slope: 3px;

    --text-size__small: 9px;
    --text-size__normal: 14px;
}