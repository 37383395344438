.download-access-log {
  width: 100%;
  text-align: left;
  overflow: hidden;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color__surface__blank-middle-dark);
}

.download-access-log__title {
  font-size: 14px;
}

.download-access-log__entry {
  margin-top: 3px;
  font-size: 10px;
  color: var(--color__text-main__action-darker);
}

.download-access-log__entry-value {
  font-weight: 500;
  color: var(--pattern__color);
}

.download-access-log__title,
.download-access-log__entry {
  float: left;
  width: 100%;
  padding: 1px 6px;
}