.wacky-logo__simple {
  width: 96px;
  height: 96px;
  margin: 0 auto;
}

.wacky-login__title {
  box-sizing: border-box;
  color: var(--color__text-main__blank);

  font-size: 18px;
  font-weight: 700;

  margin-top: 18px;
  margin-bottom: 24px;
}

.login-area {
  position: relative;

  margin: 0 auto;
  box-sizing: border-box;

  border: 0;
}

.login-area__text-success-server {
  width: 260px;
  margin-top: 20px;

  color: var(--color__status-success);
  font-size: var(--font-size__status);
}

.login-area__text-error-server {
  width: 260px;
  margin-top: 20px;

  color: var(--color__status-error);
  font-size: var(--font-size__status);
}

.login-area__text {
  /* position: relative; */
  width: 260px;
  overflow: auto;
  margin: 0 auto;
  overflow: hidden;

  text-align: center;
}

.login-area__text-secondary {
  margin-top: 75px;
  margin-bottom: 10px;

  font-size: 12px;
  color: #777;
}

.login-area__text-subsecondary {
  margin-top: 20px;

  font-size: 12px;
  color: #bbb;

  line-height: 1.3em;
  letter-spacing: 0.1px;
}

.icon-login {
  font-size: 20px;
}

.icon-login:hover {
  cursor: pointer;
}

.icon-login__password-show-toggle-on,
.icon-login__password-show-toggle-off:hover {
  color: var(--color__surface__action-dark);
}

.icon-login__password-show-toggle-on:hover,
.icon-login__password-show-toggle-off {
  color: var(--color__surface__action);
}

.button-login__password-show-toggle {
  position: relative; /* for z-index to take effect */
  z-index: 1000;

  float: right;
  margin-top: 9px;
  margin-bottom: -70px;
  margin-right: 13px;
}

.action__login__main {
  display: flex;
  -webkit-box-pack: var(--button-align,center);
  justify-content: var(--button-align,center);
  -webkit-box-align: center;

  -webkit-font-smoothing: antialiased;

  line-height: 20px;
  letter-spacing: -0.125px;

  border-radius: 5px;
  padding: 8px 0;
  /* font-size: 9px; */
  font-weight: 700;
  width: 100%;
  min-height: 40px;

  transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
}

.action__login__wrapper {
  margin-top: 20px;
}

.action__login__extra {
  width: 260px;
  margin: 0 auto;
  margin-top: 7px;
}

.login-area__text-gap {
  width: 100%;
  margin-top: 25px;
}

.data__login__username-wrapper,
.data__login__email-wrapper,
.data__login__password-wrapper {
  margin-top: 5px;
}

.data__login__username label.Mui-focused,
.data__login__email label.Mui-focused,
.data__login__password label.Mui-focused {
  color: var(--color__surface__action);
}

.data__login__username input,
.data__login__email input,
.data__login__password input {
  padding: 9px 12px;
  background: rgba(0,0,0,0.02);
}

.data__login__username label,
.data__login__email label,
.data__login__password label {
  margin-top: -3px;
  line-height: 1;
  letter-spacing: 1;
}