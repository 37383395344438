.wacky-logo__wrapper {
    width: 400px;
    height: 280px;
    background: url('../../../img/logo_wrapper.png');
    mix-blend-mode: multiply;
    z-index: 9999;
    position: absolute;
    margin-top: -216px;
    margin-left: -73px;
    transform: scale(0.8);

    /* filter: hue-rotate(180deg); */
}

.wacky-logo__wrapper a {
    width: 345px;
    height: 221px;
    display: inline-block;
    margin-top: 16px;
    margin-left: 46px;
}

.wacky-logo {
    position: absolute;
    /* margin-top: 128px; */
    /* margin-left: 88px; */
    margin-top: 112px;
    margin-left: 42px;
    background: url('../../../img/logo_content.png');
    width: 226px;
    height: 88px;
    /* background: url('../../../img/logo_content_beta.png'); */
    /* width: 242px; */
    /* height: 90px; */
}

.wacky-logo__unique-selling-point {
    position: absolute;
    /* margin-top: 85px; */
    /* margin-left: 71px; */
    margin-top: 69px;
    margin-left: 25px;
    transform: rotate(-9.3deg);

    font-family: monospace;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid var(--color__surface__action-darker); /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 1.2s steps(60, end),
      blink-caret .5s step-end,
      disappear .5s step-end;
}

/* The typing effect */
@keyframes typing {
    from { width: 1px; margin-top: 89px; }
    to { width: 277px; margin-top: 68px; }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: var(--color__surface__action-darker) }
}

/* The disappearing effect */
@keyframes disappear {
    from { opacity: 1 }
    70% { opacity: 1 }
    to { opacity: 0 }
}