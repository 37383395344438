/* need it for Material UI components */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

/* from https://css-tricks.com/explain-the-first-10-lines-of-twitter-source-code/: */
/* do not allow weird text size adjustments */
html{
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

/* =============================================================================== */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, time, mark, audio, video {
    border: 0;
    /* border: 1px dashed black; */ /* for visual debugging */

    margin: 0;
    padding: 0;
    font-size: 100%;
    /* font: inherit; */
    font-weight: 400;
    vertical-align: baseline;
    color: inherit;
    text-decoration: inherit;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

@-webkit-keyframes hue-filter-rotation__animation {
    0% {
        -webkit-filter: invert(1) blur(0px) hue-rotate(0deg);
    }

    50% {
        -webkit-filter: invert(1) blur(0px) hue-rotate(180deg);
    }
  
    100% {
        -webkit-filter: invert(1) blur(0px) hue-rotate(360deg);
    }
}
  
@keyframes hue-filter-rotation__animation {
    0% {
        filter: invert(1) blur(0px) hue-rotate(0deg);
    }

    50% {
        filter: invert(1) blur(0px) hue-rotate(180deg);
    }
          
    100% {
        filter: invert(1) blur(0px) hue-rotate(360deg);
    }
}

html {
    line-height: 1;
    /* animation: hue-filter-rotation__animation 30s infinite; */
    /* filter: hue-rotate(180deg); */
}

/* ========================================================================= */

.neumorph__panel {
    border-radius: var(--border__panel__radius);
    border: var(--border__highlight);
    box-shadow:  var(--border__panel__slope) var(--border__panel__slope) var(--border__panel__slope) var(--color__shadow-main),
                calc(-1 * var(--border__panel__slope)) calc(-1 * var(--border__panel__slope)) var(--border__panel__slope) var(--color__highlight-main);
}

.neumorph__panel-inset {
    border-radius: var(--border__panel__radius);
    border: var(--border__highlight);
    box-shadow: inset var(--border__panel__slope) var(--border__panel__slope) var(--border__panel__slope) var(--color__shadow-main),
                inset calc(-1 * var(--border__panel__slope)) calc(-1 * var(--border__panel__slope)) var(--border__panel__slope) var(--color__highlight-main);
}

.neumorph__surface-blank__flat { background: var(--color__surface__blank); }
.neumorph__surface-blank__pressed { background: linear-gradient(145deg, var(--color__surface__blank-dark), var(--color__surface__blank-light)); }
.neumorph__surface-blank__bump { background: linear-gradient(145deg, var(--color__surface__blank-light), var(--color__surface__blank-dark)); }

.neumorph__surface-blank__flat,
.neumorph__surface-blank__pressed,
.neumorph__surface-blank__bump {
    color: var(--color__text-main__blank);
}

.neumorph__surface-action__flat { background: var(--color__surface__action); }
.neumorph__surface-action__pressed { background: linear-gradient(145deg, var(--color__surface__action-dark), var(--color__surface__action-light)); }
.neumorph__surface-action__bump { background: linear-gradient(145deg, var(--color__surface__action-light), var(--color__surface__action-dark)); }

.neumorph__surface-action__flat:enabled { background: var(--color__surface__action); }
.neumorph__surface-action__pressed:enabled { background: linear-gradient(145deg, var(--color__surface__action-dark), var(--color__surface__action-light)); }
.neumorph__surface-action__bump:enabled { background: linear-gradient(145deg, var(--color__surface__action-light), var(--color__surface__action-dark)); }

.neumorph__surface-action__flat:disabled { background: var(--color__surface__blank-dark); }
.neumorph__surface-action__pressed:disabled { background: linear-gradient(145deg, var(--color__surface__blank-darker), var(--color__surface__blank-dark)); }
.neumorph__surface-action__bump:disabled { background: linear-gradient(145deg, var(--color__surface__blank-dark), var(--color__surface__blank-darker)); }

.neumorph__surface-action__flat:enabled:hover { background: var(--color__surface__action-dark); }
.neumorph__surface-action__pressed:enabled:hover { background: linear-gradient(145deg, var(--color__surface__action-darker), var(--color__surface__action)); }
.neumorph__surface-action__bump:enabled:hover { background: linear-gradient(145deg, var(--color__surface__action), var(--color__surface__action-darker)); }

.neumorph__surface-action__flat:hover,
.neumorph__surface-action__pressed:hover,
.neumorph__surface-action__bump:hover {
    cursor: pointer;
}

.neumorph__surface-action__flat,
.neumorph__surface-action__pressed,
.neumorph__surface-action__bump {
    color: var(--color__text-main__action);
}

.boring-page__default-block {
    position: relative;

    width: 100%;
    margin: 0 auto;
    height: 400px;
    box-sizing: border-box;
  
    color: var(--color__text-main__blank);
}

.boring-page__default-title {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    margin-top: 17px;
    margin-bottom: 10px;
    color: var(--pattern__color);
    font-weight: 700;
    font-size: 24px;
}

.boring-page__default-text {
    padding: 10px 30px;
    font-size: 14px;
}

.boring-page__default-text textarea {
    background: transparent;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    height: 310px;
    border: 0;
    resize: none;
    margin: 0;
}

/* ================================================================ */

.qr-code__code {
    width: 130px;
    height: 130px;
    background: 'white';
    cursor: 'pointer';
    float: 'left';
}

/* QR code background */
.qr-code__code path:nth-child(2) {
    fill: var(--pattern__color);
}

/* ================================================================ */

body {
    background-color: var(--color__bg-main);
    min-height: 100vh;
    height: 100%;
}

.action__link-secondary {
    color: var(--color__text-secondary__blank);
}

.action__link-secondary:hover {
    cursor: pointer;
    color: var(--color__surface__action);
    font-weight: 700;
    border-bottom: 1px solid var(--color__surface__action);
}

.action__link__just-text,
.action__link__just-text:enabled {
    color: var(--color__surface__action);
}

.action__link__just-text:hover,
.action__link__just-text:enabled:hover {
    cursor: pointer;
    color: var(--color__surface__action-dark);
    font-weight: 700;
}

.text-s {
    font-size: var(--text-size__small);
}

.text-m {
    font-size: var(--text-size__normal);
}

/* ========================================================================= */

@font-face {
    font-family: 'SourceCodePro';
    src: url('./fonts/SourceCodePro-Regular.ttf');
}

* {
    font-family: 'Roboto', sans-serif;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
    src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(./fonts/MaterialIcons-Regular.woff2) format('woff2'),
    url(./fonts/MaterialIcons-Regular.woff) format('woff'),
    url(./fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 22px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.material-icons__big {
    font-size: 80px;
}

.material-icons__mid {
    font-size: 30px;
}

.material-icons__small {
    font-size: 16px;
}

/* ========================================================================= */

.container__default {
    transition: transform 0.5s ease-in-out;
  
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.wacky-uploader-wrapper {
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
  
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    
    max-width: 460px;
    /* width: 90%; */
}

.wacky-uploader {
    margin-top: 170px;
    min-width: 320px;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 5px;
    padding-right: 5px;
}

.wacky-uploader-wrapper,
.panel-wide {
    padding-top: 80px;
    padding-bottom: 35px;
}

.panel-wide {
    /* width: 800px; */
    /* margin-left: -190px; */
    /* margin-right: -190px; */
}

/* TODO: refactor this one for proper resolutions */
@media (max-width: 800px) {
    .panel-wide {
        width: 275px;
    }
}

.generative-avatar {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    padding-top: 9px;
    box-sizing: border-box;
    border-radius: 50%;
    box-sizing: border-box;
}