.generative-avatar__settings {
    width: 96px;
    height: 96px;
    font-weight: 500;
    padding-top: 23px;
    font-size: 50px;
}

.wacky-logo__simple {
    width: 96px;
    height: 96px;
    margin: 0 auto;
}
  
.wacky-settings__title {
    box-sizing: border-box;
    color: var(--color__text-main__blank);
  
    font-size: 18px;
    font-weight: 700;
  
    margin-top: 18px;
    margin-bottom: 24px;
}

.wacky-settings__tertiary {
    margin-top: 10px;
    font-size: 10px;
    color: var(--color__surface__blank-darker);
}

.user-value {
    color: var(--color__surface__action-dark);
}

.settings-area {
    position: relative;
  
    margin: 0 auto;
    box-sizing: border-box;
  
    border: 0;

    

    padding-top: 50px;
    padding-bottom: 50px;
}
  
.settings-area__text {
    /* position: relative; */
    width: 260px;
    overflow: auto;
    margin: 0 auto;
    overflow: hidden;
  
    text-align: center;
}
  
.action__setting,
.action__setting-disabled {
    margin-top: 20px;
  
    font-size: 14px;
    
    line-height: 1.3em;
    letter-spacing: 0.1px;
}

.action__setting {
    color: var(--color__text-main__blank);
}

.action__setting-disabled {
    /* color: var(--color__surface__blank-dark); */
    color: var(--color__surface__blank-darker);
}

.action__setting:hover {
    color: var(--color__surface__action);
}
  
.action__settings__wrapper {
    margin-top: 20px;
}