.footer {
    z-index: 2000;
    bottom: 0;
    
    right: 0;
    
    width: 100%;
    display: inline-block;
    /* height: 110px;
    overflow: hidden; */
    color: var(--color__surface__blank-middle-dark);
    /* color: var(--color__surface__blank-light); */
    /* background-color: var(--color__surface__blank-very-dark); */
    padding-top: 4px;

    /* mix-blend-mode: multiply; */
}

.footer__summary-stats {
    right: 0;
    bottom: 0;
    text-align: center;
    width: 100%;
    float: right;
    opacity: 0.5;
    margin-top: 3px;
    line-height: 1.3em;
    padding-bottom: 5px;
}

.footer__delimiter {
    margin-left: 15px;
    margin-right: 15px;
}

.footer__delimiter-mini {
    margin-left: 7px;
    margin-right: 7px;
}

.footer__secondary-menus {
    width: 100%;
    text-align: right;
    float: right;
}

.footer__secondary-menus__line-1,
.footer__secondary-menus__line-2 {
    width: 100%;
    margin-top: 15px;
    text-align: center;
    position: relative;
}

.footer__secondary-menus__line-1 {
    font-size: 14px;
    margin-bottom: 10px;
}

.footer__secondary-menus__line-2 {
    font-size: 12px;
    opacity: 0.5;
}

.footer__summary-stats {
    font-size: 12px;
    margin-top: 7px;
}

.footer__secondary-menus a,
.footer__summary-stats a {
    font-weight: 700;
}

.footer__secondary-menus a,
.footer__summary-stats a {
    /* color: var(--color__surface__action-dark) */
}

.footer__secondary-menus a:hover,
.footer__summary-stats a:hover {
    font-weight: 700;
    color: var(--color__surface__action-dark);
}

.footer__secondary-item__icon {
    /* margin-left: 4px; */
}

.footer__secondary-item__icon svg {
    margin-bottom: -3px;
}